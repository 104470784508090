import styled, { css, extensionsHook, keyframes } from '../styled-components';
const headerFontSize = {
  1: '1.85714em',
  2: '1.57143em',
  3: '1.27em'
};
export const headerCommonMixin = level => css(["font-family:", ";font-weight:400;font-size:", ";"], props => props.theme.typography.headings.fontFamily, headerFontSize[level]);
export const H1 = styled.h1.withConfig({
  componentId: "shv3r-0"
})(["", ";color:", ";", ";"], headerCommonMixin(1), props => props.theme.colors.primary.main, extensionsHook('H1'));
export const H2 = styled.h2.withConfig({
  componentId: "shv3r-1"
})(["", ";color:black;", ";"], headerCommonMixin(2), extensionsHook('H2'));
export const H3 = styled.h2.withConfig({
  componentId: "shv3r-2"
})(["", ";color:black;", ";"], headerCommonMixin(3), extensionsHook('H3'));
export const blinkKeyframe = keyframes(["from,to{color:transparent;}50%{color:#FFFFFF;}"]);
export const Blinker = styled.span.withConfig({
  componentId: "shv3r-3"
})(["color:#FFFFFF;display:inline-block;font-weight:800;font-size:15px;font-family:Courier,monospace;color:#2E3D48;-webkit-animation:1s ", "  step-end infinite;-moz-animation:1s ", "  step-end infinite;-ms-animation:1s ", "  step-end infinite;-o-animation:1s ", "  step-end infinite;animation:1s ", " step-end infinite;"], blinkKeyframe, blinkKeyframe, blinkKeyframe, blinkKeyframe, blinkKeyframe);
export const RightPanelHeader = styled.h3.withConfig({
  componentId: "shv3r-4"
})(["color:", ";text-align:center;text-transform:uppercase;padding:10px 0;background-color:rgba(0,0,0,0.2);margin-top:0;font-weight:500;font-size:12px;", ";font-family:Courier,monospace;"], ({
  theme
}) => theme.rightPanel.textColor, extensionsHook('RightPanelHeader'));
export const UnderlinedHeader = styled.h5.withConfig({
  componentId: "shv3r-5"
})(["border-bottom:1px solid rgba(38,50,56,0.3);margin:1em 0 1em 0;color:rgba(38,50,56,0.5);font-weight:500;text-transform:uppercase;font-size:0.929em;line-height:20px;padding:10px 5px;", ";"], extensionsHook('UnderlinedHeader'));