import { transparentize } from 'polished';
import styled, { extensionsHook } from '../styled-components';
import { PropertyNameCell } from './fields-layout';
import { ShelfIcon } from './shelfs';
export const ClickablePropertyNameCell = styled(PropertyNameCell).withConfig({
  componentId: "sc-1noysbl-0"
})(["cursor:pointer;", "{height:", ";width:", ";polygon{fill:", ";}}"], ShelfIcon, ({
  theme
}) => theme.schema.arrow.size, ({
  theme
}) => theme.schema.arrow.size, ({
  theme
}) => theme.schema.arrow.color);
export const FieldLabel = styled.span.withConfig({
  componentId: "sc-1noysbl-1"
})(["vertical-align:middle;font-size:0.929em;line-height:20px;"]);
export const TypePrefix = styled(FieldLabel).withConfig({
  componentId: "sc-1noysbl-2"
})(["color:", ";"], props => transparentize(0.2, props.theme.schema.typeNameColor));
export const TypeName = styled(FieldLabel).withConfig({
  componentId: "sc-1noysbl-3"
})(["color:", ";"], props => props.theme.schema.typeNameColor);
export const TypeTitle = styled(FieldLabel).withConfig({
  componentId: "sc-1noysbl-4"
})(["color:", ";"], props => props.theme.schema.typeTitleColor);
export const TypeFormat = TypeName;
export const RequiredLabel = styled(FieldLabel.withComponent('div')).withConfig({
  componentId: "sc-1noysbl-5"
})(["color:", ";font-size:", ";font-weight:normal;margin-left:20px;line-height:1;text-transform:uppercase;font-size:9px;font-weight:600;border:solid 1px;padding:4px 8px;border-radius:30px;background:#E09C64;color:#ffffff;position:absolute;"], props => props.theme.schema.requireLabelColor, props => props.theme.schema.labelsTextSize);
export const ConditionalLabel = styled(FieldLabel.withComponent('div')).withConfig({
  componentId: "sc-1noysbl-6"
})(["color:", ";font-size:", ";font-weight:normal;margin-left:20px;line-height:1;text-transform:uppercase;font-size:9px;font-weight:600;border:solid 1px;padding:4px 8px;border-radius:30px;background:rgb(176,196,50);color:#ffffff;position:absolute;"], props => props.theme.schema.requireLabelColor, props => props.theme.schema.labelsTextSize);
export const RecursiveLabel = styled(FieldLabel).withConfig({
  componentId: "sc-1noysbl-7"
})(["color:", ";font-size:13px;"], ({
  theme
}) => theme.colors.warning.main);
export const NullableLabel = styled(FieldLabel).withConfig({
  componentId: "sc-1noysbl-8"
})(["color:#3195a6;font-size:13px;"]);
export const PatternLabel = styled(FieldLabel).withConfig({
  componentId: "sc-1noysbl-9"
})(["color:#3195a6;&::before,&::after{content:'/';font-weight:bold;}"]);
export const ExampleValue = styled(FieldLabel).withConfig({
  componentId: "sc-1noysbl-10"
})(["border-radius:20px;", ";& + &{margin-left:0;}", ";"], () => `
    background-color: #eef4fb;
    color: #0E5FC2;
    margin: 5px;
    padding: 1px 5px;
    border: 1px solid #5e95d8;
}`, extensionsHook('ExampleValue'));
export const ConstraintItem = styled(FieldLabel).withConfig({
  componentId: "sc-1noysbl-11"
})(["border-radius:2px;", ";& + &{margin-left:0;}", ";"], ({
  theme
}) => `
    background-color: ${transparentize(0.95, theme.colors.primary.light)};
    color: ${transparentize(0.1, theme.colors.primary.main)};

    margin: 0 ${theme.spacing.unit}px;
    padding: 0 ${theme.spacing.unit}px;
    border: 1px solid ${transparentize(0.9, theme.colors.primary.main)};
}`, extensionsHook('ConstraintItem'));