import * as React from 'react';
import { SecurityRequirements } from '../SecurityRequirement/SecurityRequirement';
import { observer } from 'mobx-react';
import { Badge, DarkRightPanel, H2, MiddlePanel, Row } from '../../common-elements';
import { OptionsContext } from '../OptionsProvider';
import { ShareLink } from '../../common-elements/linkify';
import { Endpoint } from '../Endpoint/Endpoint';
import { ExternalDocumentation } from '../ExternalDocumentation/ExternalDocumentation';
import { Markdown } from '../Markdown/Markdown';
import { Parameters } from '../Parameters/Parameters';
import { RequestSamples } from '../RequestSamples/RequestSamples';
import { ResponsesList } from '../Responses/ResponsesList';
import { ResponseSamples } from '../ResponseSamples/ResponseSamples';
import { OperationModel as OperationType } from '../../services/models';
import styled from '../../styled-components';
import { Extensions } from '../Fields/Extensions';
const OperationRow = styled(Row).withConfig({
  componentId: "bf7xj3-0"
})(["backface-visibility:hidden;contain:content;overflow:hidden;"]);
const Description = styled.div.withConfig({
  componentId: "bf7xj3-1"
})(["margin-bottom:", "px;"], ({
  theme
}) => theme.spacing.unit * 6);
const Snippet = styled.div.withConfig({
  componentId: "bf7xj3-2"
})(["padding:10px;text-transform:uppercase;color:#ffffff;text-align:center;text-transform:uppercase;padding:20px 0;font-weight:500;font-size:15px;"]);
export interface OperationProps {
  operation: OperationType;
}
@observer
export class Operation extends React.Component<OperationProps> {
  render() {
    const {
      operation
    } = this.props;
    const {
      name: summary,
      description,
      responseDescription,
      deprecated,
      externalDocs
    } = operation;
    const hasDescription = !!(description || externalDocs);
    return <OptionsContext.Consumer>
        {options => <OperationRow>
            <MiddlePanel>
              <H2>
                <ShareLink to={operation.id} />
                {summary} {deprecated && <Badge type="warning"> Deprecated </Badge>}
              </H2>
              {options.pathInMiddlePanel && <Endpoint operation={operation} inverted={true} />}
              {hasDescription && <Description>
                  {description !== undefined && <Markdown source={description} />}
                  {externalDocs && <ExternalDocumentation externalDocs={externalDocs} />}
                </Description>}
              <Extensions extensions={operation.extensions} />
              <SecurityRequirements securities={operation.security} />
              <Parameters parameters={operation.parameters} body={operation.requestBody} />
              <ResponsesList responses={operation.responses} description={responseDescription ? responseDescription : ''} />
            </MiddlePanel>
            <DarkRightPanel>
              <Snippet>CODE SNIPPET</Snippet>
              <RequestSamples operation={operation} />
              <ResponseSamples operation={operation} />
            </DarkRightPanel>
          </OperationRow>}
      </OptionsContext.Consumer>;
  }

}