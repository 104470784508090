// import { transparentize } from 'polished';
import * as React from 'react';
import styled from '../../styled-components';
import { Link, UnderlinedHeader } from '../../common-elements/';
import { SecurityRequirementModel } from '../../services/models/SecurityRequirement';
import { linksCss } from '../Markdown/styled.elements';
const ScopeName = styled.code.withConfig({
  componentId: "zmmjx2-0"
})(["font-size:", ";font-family:", ";border:1px solid ", ";margin:0 3px;padding:0.2em;display:inline-block;line-height:1;&:after{content:',';}&:last-child:after{content:none;}"], props => props.theme.typography.code.fontSize, props => props.theme.typography.code.fontFamily, ({
  theme
}) => theme.colors.border.dark);
const SecurityRequirementAndWrap = styled.span.withConfig({
  componentId: "zmmjx2-1"
})(["&:after{content:' AND ';font-weight:bold;}&:last-child:after{content:none;}", ";"], linksCss);
const SecurityRequirementOrWrap = styled.span.withConfig({
  componentId: "zmmjx2-2"
})(["&:before{content:'( ';font-weight:bold;}&:after{content:' ) OR ';font-weight:bold;}&:last-child:after{content:' )';}&:only-child:before,&:only-child:after{content:none;}", ";"], linksCss);
export interface SecurityRequirementProps {
  security: SecurityRequirementModel;
}
export class SecurityRequirement extends React.PureComponent<SecurityRequirementProps> {
  render() {
    const security = this.props.security;
    return <SecurityRequirementOrWrap>
        {security.schemes.map((scheme, index) => {
        return <SecurityRequirementAndWrap key={`sec-req-${index}-${scheme.id}`}>
              <Link to={scheme.sectionId}>{scheme.id}</Link>
              {scheme.scopes.length > 0 && ' ('}
              {scheme.scopes.map((scope, index) => <ScopeName key={`scone-name-${index}-${scope}`}>{scope}</ScopeName>)}
              {scheme.scopes.length > 0 && ') '}
            </SecurityRequirementAndWrap>;
      })}
      </SecurityRequirementOrWrap>;
  }

}
const AuthHeaderColumn = styled.div.withConfig({
  componentId: "zmmjx2-3"
})(["flex:1;"]);
const SecuritiesColumn = styled.div.withConfig({
  componentId: "zmmjx2-4"
})(["width:", ";"], props => props.theme.schema.defaultDetailsWidth);
const AuthHeader = styled(UnderlinedHeader).withConfig({
  componentId: "zmmjx2-5"
})(["display:inline-block;margin:0;"]);
const Wrap = styled.div.withConfig({
  componentId: "zmmjx2-6"
})(["width:100%;display:flex;margin:1em 0;"]);
export interface SecurityRequirementsProps {
  securities: SecurityRequirementModel[];
}
export class SecurityRequirements extends React.PureComponent<SecurityRequirementsProps> {
  render() {
    const securities = this.props.securities;

    if (!securities.length) {
      return null;
    }

    return <Wrap>
        <AuthHeaderColumn>
          <AuthHeader>Authorizations: </AuthHeader>
        </AuthHeaderColumn>
        <SecuritiesColumn>
          {securities.map((security, idx) => <SecurityRequirement key={`recurity-req-${idx}`} security={security} />)}
        </SecuritiesColumn>
      </Wrap>;
  }

}